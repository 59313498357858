import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { nanoid } from 'nanoid'
import BadWordsFilter from 'bad-words'

import { Room } from './routes/Room'

import { NotFound } from './components/NotFound'

const Routes = () => {
    return (
        <>
            <Switch>
                <Route path="/:room" component={Room} />
                {/* If you navigate to the homepage, get redirected into a random room. */}
                <Route exact path="/">
                    <Redirect to={`/${buildRandomRoomId()}`} />
                </Route>
                <Route path="/" component={NotFound} />
            </Switch>
        </>
    )
}

function buildRandomRoomId() {
    const badWordsFilter = new BadWordsFilter()
    let base = nanoid(8)
    let attemptCounter = 0

    while (attemptCounter++ < 10) {
        if (!badWordsFilter.isProfane(base)) {
            return base
        }
        base = nanoid(8)
    }
    return base
}

export default class App extends React.Component {
    render() {
        return (
            <>
                <Router>
                    <Routes />
                </Router>
            </>
        )
    }
}
