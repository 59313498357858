import React from 'react'
import { Helmet } from 'react-helmet'

import assert from 'assert'

import Peer from 'peerjs'
import io, { Socket } from 'socket.io-client'

import cloneDeep from 'lodash/cloneDeep'

// import Peer from 'peerjs'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import InfoIcon from '@material-ui/icons/Info'
import LinkIcon from '@material-ui/icons/Link'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VolumePercentOffIcon from '@material-ui/icons/VoiceOverOff'
import VolumePercentIcon from '@material-ui/icons/RecordVoiceOver'
import DeafenOffIcon from '@material-ui/icons/VolumeOff'
import DeafenIcon from '@material-ui/icons/VolumeUp'

//topBar icons
import { DocumentShareIcon } from '../icons/DocumentShareIcon'
import { ShareScreenIcon } from '../icons/ShareScreenIcon'
import { WhiteboardIcon } from '../icons/WhiteboardIcon'

import { LoadingScreen } from '../components/LoadingScreen'
import { Modal } from '../components/Modal'
import { TooltipBootstrap as Tooltip, TooltipBootstrap } from '../components/TooltipBootstrap'
import { TooltipMajor } from '../components/TooltipMajor'

import * as LocalStorageUtils from '../utils/localStorageUtils'
import * as NicknameGenerator from '../utils/nicknameGenerator'
import * as PeerUtils from '../utils/peerUtils'
import * as SocketUtils from '../utils/socketUtils'
import * as Euclidean from '../utils/euclideanUtils'
// import { audioCalc, angleCalc, calcPuckGain } from '../utils/spatialAudio'

import * as SpatialAudioUtils from '../utils/spatialAudio'

import * as Util from '../utils/util'

import './Room.scss'

const PING_INTERVAL_MS = 3000

/**
 * @typedef {Object} PeerData
 * Stores non-UI data about a peer-connected user.
 *
 * @property {Peer.MediaConnection} call
 * @property {React.RefObject<HTMLVideoElement>>} [videoRef]
 */

/**
 * @typedef {Object} PeerDetails
 * Stores UI data about a peer-connected user.
 *
 * @property {MediaStream} [stream]
 * MediaStream from peer-connected user.
 *
 * @property {number} [volumePercent]
 * Number in range [0, 100]. Percent based on orientations of our puck, their puck, and our focus
 * direction.
 *
 * @property {boolean} [muted]
 * Whether the user is not streaming their audio (muted).
 * @property {boolean} [noCam]
 * Whether the user is not streaming their video.
 *
 * @property {boolean} [disconnected]
 */

export class Room extends React.Component {
    state = {
        /* ——————————————————————————————————————————— */
        /* DATA */

        /**
         * My own peer ID.
         * @type {string | null}
         */
        peerId: null,

        /**
         * A map containing details on all our calls, either ongoing or finished.
         * One call per peer. Keyed by peerId. Each property is a PeerDetails object.
         * @type {Object.<string, PeerDetails>}
         */
        peers: {},

        /* ——————————————————————————————————————————— */
        /* Loading */

        /** @type {boolean} Main content won't render until this is true. */
        didJoinRoom: false,

        /* ——————————————————————————————————————————— */
        /* Room state (controlled by server). */
        // roomDimensions: { x: null, y: null },

        /**
         * Scales each puck's display size.
         * @type {number}
         */
        puckScale: 1,

        /**
         * Latest ping from server.
         * @type {number | null}
         */
        latencyMs: null,

        /* ——————————————————————————————————————————— */
        /* UI (controlled by client) */

        /** Dimensions of a puck, in pixels, when `puckScale` is 1. */
        basePuckSize: 200,

        /** @type {"dummy" | "camera" | "screen"} */
        myVideoTrackName: 'dummy',

        /** @type {"dummy" | "mic"} */
        myAudioTrackName: 'dummy',

        /** @type {string | null} Hydrated in this.hydratePublicParams. */
        myNickname: null,

        /** @type {string} */
        nicknameFieldInput: '',

        doShowPuck: false,

        /** @type {Euclidean.Position} */
        position: { x: null, y: null },

        /** @type {boolean} Hydrated in this.hydratePublicParams. */
        isMuted: true,

        /** @type {boolean} Hydrated in this.hydratePublicParams. */
        isNoCam: false,

        deafen: false,
    }

    /**
     * Socket.io object.
     * @type {Socket}
     */
    socket = null

    /**
     * Peer object.
     * @type {Peer}
     */
    peer = null

    /**
     * My own media stream.
     * @type {MediaStream | null}
     */
    myStream = null

    myTracks = {
        dummyAudio: PeerUtils.createDummyAudioTrack(),
        dummyVideo: PeerUtils.createDummyVideoTrack('camera'),
        micAudio: null,
        cameraVideo: null,
    }

    /**
     * React ref for our own streamed video.
     * @type {React.RefObject<HTMLVideoElement> | null}
     */
    myVideoRef = null

    /**
     * Maps peer IDs to non-UI data, including MediaConnections and video refs.
     *
     * (Keyed by peer ID. One call per peer. See `PeerData` for more details.)
     *
     * @type {Object.<string, PeerData>}
     */
    peersData = {}

    get roomId() {
        return this.props.match.params.room
    }

    /** @type {RTCPeerConnection[]} */
    get peerConnections() {
        const peerEntries = Object.entries(this.peersData)
        const peerConnections = peerEntries
            .map(([_peerId, { call }]) => call.peerConnection)
            .filter(Util.exists)
        return peerConnections
    }

    get puckSize() {
        return this.state.puckScale * this.state.basePuckSize
    }
    get puckSizePx() {
        return `${this.puckSize}px`
    }
    get pointerTransformOrigin() {
        const puckSize = this.puckSize
        return `-${(puckSize * 0.54) / 2}px 0`
    }

    get isModalOpen() {
        const modalRoutes = [`/${this.roomId}/settings`]
        return modalRoutes.includes(window.location.pathname)
    }

    /** Movement using WASD is disabled when modals are open. */
    get isMovementEnabled() {
        return !this.isModalOpen
    }

    get isRefocusEnabled() {
        return !this.isModalOpen
    }

    constructor(props) {
        super(props)
        this.myVideoRef = React.createRef()
    }

    async componentDidMount() {
        // 1. Populate local settings and local stream.
        await this.setupLocalState()

        /* 2. Connect to WebSockets and WebRTC. */
        const peer = PeerUtils.instantiatePeer()
        const socket = SocketUtils.instantiateIo()
        this.peer = peer
        this.socket = socket
        // this.setState({ socket, peer })

        // 2.a. Open peer connection.
        const peerId = await new Promise((resolve) => peer.on('open', resolve))
        console.log(`Opened peer connection. My ID: "${peerId}"`)

        // 2.b. Join socket.io room.
        const roomId = this.props.match.params.room
        socket.emit('join-room', roomId, peerId, this.getPublicParamsFromState())
        // Receive initial params from first handshake with server (position and more).
        const initialRoomState = await new Promise((resolve) =>
            socket.on('initial-room-state', async (state) => {
                await this.handleInitialRoomState(state)
                resolve(state)
            }),
        )
        console.log(`initialRoomState`, initialRoomState)

        // Set up ping/pong.
        this.beginPingPong()

        // 2.c. Save details and render room.
        await new Promise((resolve) =>
            this.setState({ peerId, didJoinRoom: true, doShowPuck: true }, resolve),
        )

        // 2.d. Attach stream to our videoRef.
        const myVideoEl = this.myVideoRef.current
        myVideoEl.srcObject = this.myStream
        console.log(`myVideoEl srcObject:`, myVideoEl.srcObject)
        myVideoEl.muted = true

        // 3. Start listening for a call.
        peer.on('call', this.handleCall)
        console.log(`ENGAGED.`)
        socket.emit('engaged', roomId, peerId)

        // Finally, try to get camera/microphone media.
        navigator.mediaDevices
            .getUserMedia({
                video: true,
                audio: true,
            })
            .then(this.handleGetUserMediaStream)

        /** Handle when another user is engaged. */
        socket.on('user-engaged', this.handleNewUserEngaged)

        /** Handle when another user adjusts their public params. */
        socket.on('public-params-changed', this.handlePeerPublicParamsChanged)

        /** Handle when another user disconnects. */
        socket.on('user-disconnected', this.handleUserDisconnect)
    }

    /**
     * Sets up local state.
     * @return {Promise<void>}
     */
    setupLocalState = async () => {
        let statePayload = {}
        // 1. Prepare local settings: muted, noCam.
        Object.assign(statePayload, {
            isMuted: true,
            isNoCam: false,
        })
        // 2. Prepare nickname. If we don't have one, create one.
        let myNickname = LocalStorageUtils.get('myNickname')
        if (myNickname === null) {
            myNickname = NicknameGenerator.generateNickname()
            LocalStorageUtils.set('myNickname', myNickname)
        }
        Object.assign(statePayload, {
            myNickname,
        })

        // 2. Prepare myStream with dummy video and audio streams.
        const myStream = new MediaStream([this.myTracks.dummyAudio, this.myTracks.dummyVideo])
        this.myStream = myStream

        // Finally, setState and return.
        return new Promise((resolve) => this.setState(statePayload, resolve))
    }

    /** @returns initial public params, derived from state, to be sent to server or peer-connected users. */
    getPublicParamsFromState = () => ({
        muted: this.state.isMuted,
        noCam: this.state.isNoCam,
        nickname: this.state.myNickname,
        dispVolumePercent: this.state.dispVolumePercent,
        deafen: this.state.deafen,
    })

    /** Begin sending pings to server, and begin handling pongs. */
    beginPingPong = () => {
        assert.notStrictEqual(this.socket, null)
        // Set up ping/pong and event listener.
        setInterval(() => this.socket.emit('regularPing', Date.now()), PING_INTERVAL_MS)
        this.socket.on('regularPong', this.handlePong)
    }

    /**
     * Handles when we (B) are already in a room, and a new user (C) has just joined the room.
     * @type {object} userDetails
     */
    handleNewUserEngaged = (userId, userDetails) => {
        console.log(`Calling: "${userId}" (my ID: ${this.state.peerId})`)
        if (this.state.peerId === null || userId === this.state.peerId) {
            // Don't try to call oneself.
            console.log(`Tried to call oneself`)
            return
        }
        const peer = this.peer
        const myStream = this.myStream

        const myStreamMetadata = {
            publicParams: this.getPublicParamsFromState(),
        }

        // 1. When we call a user, we'll send them our own video stream.
        const call = peer.call(userId, myStream, myStreamMetadata)
        this.peersData[userId] = { call }

        // 2. When they send us back a stream, we'll take it in, then display it on our own page.
        call.on('stream', (userStream) => {
            this.handleCallStream(userId, userStream, userDetails)
        })

        call.on('close', () => {
            this.handleCallClose(userId)
        })
    }

    /**
     * Triggers when we've been called.
     * @param {Peer.MediaConnection} call
     */
    handleCall = async (call) => {
        assert.ok(this.socket)
        assert.ok(this.peer)
        assert.ok(this.myStream)
        if (call.peer === this.state.peerId) {
            // Don't take calls from oneself.
            console.log(`Tried to answer call from oneself!`)
            return
        }

        // We've been called! Answer the call with myStream.
        call.answer(this.myStream)
        const peerId = call.peer
        console.log(`Answering call from: "${peerId}"`)

        // Store call.
        this.peersData[peerId] = { call }

        // NOTE: If two users join, the user which joins latest needs to
        // respond to the video stream which comes in from the caller/answerer.
        const callerDetails = call.publicParams
        call.on('stream', (userStream) => {
            this.handleCallStream(peerId, userStream, callerDetails)
        })
        call.on('close', () => {
            this.handleCallClose(peerId)
        })
    }

    handleCallStream = (userId, userStream, userDetails) => {
        console.log(`Receiving stream from: "${userId}"`)
        // 1. Set the video ref.
        this.peersData[userId].videoRef = React.createRef()

        // NOTE: Assume that the received data is well-formed.
        let additionalPeerDetails = {}
        if (userDetails !== null && typeof userDetails === 'object') {
            const { muted, noCam, x, y, volumePercent } = userDetails
            additionalPeerDetails = {
                muted: muted !== undefined ? muted : null,
                noCam: noCam !== undefined ? noCam : null,
                x: x !== undefined ? x : null,
                y: y !== undefined ? y : null,

                /////////////////////////////////////////////////////////////////
                volumePercent: volumePercent !== undefined ? volumePercent : null,
            }
        }
        console.log({ userDetails, additionalPeerDetails })

        // 2. Set the stream.
        this.setState(
            ({ peers }) => ({
                peers: {
                    ...peers,
                    [userId]: {
                        ...peers[userId],
                        stream: userStream,
                        ...additionalPeerDetails,
                    },
                },
            }),
            () => {
                // 3. Attach the stream to the videoRef.
                console.log({ userStream })
                this.peersData[userId].videoRef.current.srcObject = userStream
                // 4. If it came muted, mute the stream.
                if (additionalPeerDetails.muted !== undefined) {
                    userStream
                        .getAudioTracks()
                        .forEach((track) => (track.enabled = !additionalPeerDetails.muted))
                }
                if (additionalPeerDetails.noCam !== undefined) {
                    userStream
                        .getVideoTracks()
                        .forEach((track) => (track.enabled = !additionalPeerDetails.noCam))
                }
                // 5. Since we'll be rendering this user's puck, get their public params.
                const roomId = this.props.match.params.room
                console.log(`Requesting public params of user: ${userId}`)
                this.socket.emit('get-public-params', roomId, this.state.peerId, userId)
            },
        )
    }

    handleCallClose = (userId) => {
        // 1. Set disconnected to true.
        this.setState(({ peers }) => ({
            peers: {
                ...peers,
                [userId]: {
                    ...peers[userId],
                    disconnected: true,
                },
            },
        }))

        // 2. Remove video.
        if (this.peersData[userId].videoRef && this.peersData[userId].videoRef.current) {
            console.log('Closed call; REMOVING videoRef')
            this.peersData[userId].videoRef.current.remove()
        }
    }

    /** Quite similar to handleCallClose. See the inspo video for more details. */
    handleUserDisconnect = (userId) => {
        console.log({ peersData: this.peersData, disconnectedUserId: userId })
        if (this.peersData[userId] && this.peersData[userId].call) {
            this.peersData[userId].call.close()
        }

        this.setState(({ peers }) => {
            console.log(`Disconnected: "${userId}"`)
            // Close the call, then set disconnected to true.
            return {
                peers: {
                    ...peers,
                    [userId]: {
                        ...peers.userId,
                        disconnected: true,
                    },
                },
            }
        })
    }

    /* —————————————————————————————————————————————————— */

    handleGetUserMediaStream = async (userMediaStream) => {
        console.log(`handleGetUserMediaStream`)
        // Extract new video and audio stream tracks.
        let newVideoTrack
        userMediaStream.getVideoTracks().forEach((track) => (newVideoTrack = track))
        let newAudioTrack
        userMediaStream.getAudioTracks().forEach((track) => (newAudioTrack = track))
        assert.notStrictEqual(newVideoTrack, undefined)
        assert.notStrictEqual(newAudioTrack, undefined)
        // Store.
        this.myTracks.cameraVideo = newVideoTrack
        this.myTracks.micAudio = newAudioTrack
        await Util.sleep(50)

        // Replace outgoing video and audio track.
        PeerUtils.swapVideoTrack(this.myStream, this.peerConnections, newVideoTrack)
        PeerUtils.swapAudioTrack(this.myStream, this.peerConnections, newAudioTrack)

        return new Promise((resolve) =>
            this.setState({ myAudioTrackName: 'mic', myVideoTrackName: 'camera' }, resolve),
        )
    }

    /* —————————————————————————————————————————————————— */

    handleInitialRoomState = async ({ users: _users, puckScale, myInitialState: { x, y } }) => {
        console.log(`Initial params received`)
        return new Promise((resolve) => this.setState({ puckScale, position: { x, y } }, resolve))
    }

    handlePong = (milliseconds) => {
        const latency = Date.now() - milliseconds
        // console.log('pong', latency)
        this.setState({ latencyMs: latency })
    }

    /**
     * Payload takes the form { [userId]: publicParamsChanged }.
     * Example: { myUserId: { muted: false} }.
     */
    handlePeerPublicParamsChanged = async (payload) => {
        console.log({ payload })
        const entries = Object.entries(payload)
        // NOTE: This is expected to just be one entry, but could be several.
        for (const [userId, newPublicParams] of entries) {
            // 0. Handle errors.
            if (typeof this.state.peers[userId] !== 'object') {
                console.log(
                    `RACE COLLISION: received public-params-changed before defining a stream!`,
                )
                continue
            } else if (typeof newPublicParams !== 'object') {
                console.log(`ERROR: newPublicParams isn't an object:`, newPublicParams)
                continue
            }
            // 1. Determine if position changed.
            let didPositionChange = false
            if (
                this.state.peers[userId].x === undefined ||
                this.state.peers[userId].x !== newPublicParams.x
            ) {
                didPositionChange = true
            }
            if (
                this.state.peers[userId].y === undefined ||
                this.state.peers[userId].y !== newPublicParams.y
            ) {
                didPositionChange = true
            }

            // 2. Set state.
            // TODO: Since this is inefficient, try to merge these later.
            await new Promise((resolve) => {
                this.setState(
                    ({ peers }) => ({
                        peers: {
                            ...peers,
                            [userId]: {
                                ...peers[userId],
                                ...newPublicParams,
                            },
                        },
                    }),
                    resolve,
                )
            })
            const stream = this.state.peers[userId].stream
            if (stream === undefined) {
                console.log(`Can't set public params because stream is undefined: "${userId}"`)
                return
            }
            // 3. Reflect audio/video change(s) in streams.
            if (newPublicParams.muted !== undefined) {
                stream.getAudioTracks().forEach((track) => (track.enabled = !newPublicParams.muted))
            }
            if (newPublicParams.noCam !== undefined) {
                stream.getVideoTracks().forEach((track) => (track.enabled = !newPublicParams.noCam))
            }

            // 4. Reflect position changes in gains.
            if (didPositionChange) {
                this.updatePeerGains()
            }

            // this.setState(
            //     ({ peers }) => {
            //         return {
            //             peers: {
            //                 ...peers,
            //                 [userId]: {
            //                     ...peers[userId],
            //                     ...newPublicParams,
            //                 },
            //             },
            //         }
            //     },
            //     () => {
            //         const stream = this.state.peers[userId].stream
            //         if (stream === undefined) {
            //             console.log(
            //                 `Can't set public params because stream is undefined: "${userId}"`,
            //             )
            //             return
            //         }
            //         // Reflect change(s) in streams.
            //         if (newDetails.muted !== undefined) {
            //             stream
            //                 .getAudioTracks()
            //                 .forEach((track) => (track.enabled = !newDetails.muted))
            //         }
            //         if (newDetails.noCam !== undefined) {
            //             stream
            //                 .getVideoTracks()
            //                 .forEach((track) => (track.enabled = !newDetails.noCam))
            //         }
            //     },
            // )
        }
    }

    /* —————————————————————————————————————————————————— */

    handleSpatialAudio = () => {
        // let cursorX = 0
        // let cursorY = 0
    }

    /**
     * Calculates the gain for a given peer.
     * @param {string} peerId
     * @returns {number} Intended gain for peer, in range [0, 1].
     *
     * @throws {Euclidean.InvalidPositionError} When the peer has an invalid position.
     */
    calculatePeerGain = (peerId) => {
        // 1. Validate peer position info.
        const peerDetails = cloneDeep(this.state.peers[peerId])
        assert.notStrictEqual(
            this.state.peers[peerId],
            undefined,
            `peerDetails is undefined for peer ${peerId}`,
        )

        const { x, y } = peerDetails
        const peerPuckPosition = { x, y }
        Euclidean.validatePosition(peerPuckPosition) // THROWS when the peer has an invalid position.

        // 2. Calculate and return gain.
        const puckGain = SpatialAudioUtils.calcPuckGain(
            [this.state.position, this.focusDirectionDegrees],
            peerPuckPosition,
        )
        return puckGain
    }

    applyPeerGainFromState = () => {
        for (const [peerId, { stream, volumePercent }] of Object.entries(this.state.peers)) {
            if (stream !== undefined && volumePercent !== undefined) {
                let audioTrack
                stream.getAudioTracks().forEach((track) => (audioTrack = track))
                if (audioTrack === undefined) {
                    console.log(`Peer ${peerId} has well-defined mediaStream but no audio track`)
                    continue
                }
                audioTrack.gain = volumePercent / 100
            }
        }
    }

    /* —————————————————————————————————————————————————— */

    handleVideoLoadedMetadata = ({ currentTarget: video }) => {
        console.log('PLAYING VIDEO')
        video.play()
    }

    /* —————————————————————————————————————————————————— */

    handleSelfToggleMute = (_event) => {
        console.log('Toggle mute')
        const newIsMuted = !this.state.isMuted
        // Set changes locally.
        this.setState(
            ({ isMuted }) => ({ isMuted: newIsMuted }),
            // Actually enable/disable the audio.
            () => {
                this.myStream.getAudioTracks().forEach((track) => (track.enabled = !newIsMuted))
            },
        )

        // Transmit, over socket.
        console.log('Transmitting')
        const roomId = this.props.match.params.room
        this.socket.emit('set-public-params', roomId, this.state.peerId, {
            muted: newIsMuted,
        })
    }

    handleSelfToggleCam = (_event) => {
        console.log('Toggle cam', this.state.isNoCam, '->', !this.state.isNoCam)
        const newIsNoCam = !this.state.isNoCam
        // Set changes locally.
        this.setState(
            ({ isNoCam }) => ({ isNoCam: newIsNoCam }),
            // Actually enable/disable the video.
            () => {
                this.myStream.getVideoTracks().forEach((track) => (track.enabled = !newIsNoCam))
                console.log(this.myStream.getVideoTracks())
            },
        )

        // Transmit, over socket.
        console.log('Transmitting')
        const roomId = this.props.match.params.room
        this.socket.emit('set-public-params', roomId, this.state.peerId, {
            noCam: newIsNoCam,
        })
    }
    handleSelfToggleVol = (_event) => {
        //console.log('Toggle volume', this.state.dispVolumePercent, '->', !this.state.dispVolumePercent)
        const newDispVolumePercent = !this.state.dispVolumePercent
        // Set changes locally.
        this.setState(
            ({ dispVolumePercent }) => ({ dispVolumePercent: newDispVolumePercent }),
            // Actually enable/disable the video.
            () => {
                //this.myStream.getVideoTracks().forEach((track) => (track.enabled = !newIsNoCam))
                //console.log(this.myStream.getVideoTracks())
            },
        )

        // Transmit, over socket.
    }

    handleDeafen = (_event) => {
        const newDeafen = !this.state.deafen
        //console.log('Toggle volume', this.state.dispVolumePercent, '->', !this.state.dispVolumePercent)
        this.setState(
            ({ deafen }) => ({ deafen: newDeafen }),
            // Actually enable/disable the video.
            () => {
                //this.myStream.getVideoTracks().forEach((track) => (track.enabled = !newIsNoCam))
                //console.log(this.myStream.getVideoTracks())
            },
        )
        if (newDeafen) {
            for (const [peerId, { stream, volumePercent }] of Object.entries(this.state.peers)) {
                if (stream !== undefined && volumePercent !== undefined) {
                    let audioTrack
                    stream.getAudioTracks().forEach((track) => (audioTrack = track))
                    if (audioTrack === undefined) {
                        console.log(
                            `Peer ${peerId} has well-defined mediaStream but no audio track`,
                        )
                        continue
                    }
                    audioTrack.gain = 0
                }
            }
        }
    }

    handleClickCopyLink = async (_e) => {
        const textToCopy = window.location.href
        await navigator.clipboard.writeText(textToCopy)
        window.alert(`Invite link copied to clipboard :)`)
    }

    handleOpenSettingsModal = () => {
        this.props.history.push(`/${this.roomId}/settings`)
    }

    handleSetNickname = async (e) => {
        e.preventDefault()
        if (this.state.nicknameFieldInput === '') {
            // No-op if the nickname hasn't been filled out.
            return
        }

        const newNickname = this.state.nicknameFieldInput

        // Set nickname state.
        LocalStorageUtils.set('myNickname', newNickname)
        await new Promise((resolve) =>
            this.setState({ myNickname: newNickname, nicknameFieldInput: '' }, resolve),
        )

        // TODO: Send new nickname state to server + to peers.

        // Exit modal.
        this.handleCloseSettingsModal()
    }

    handleCloseSettingsModal = () => this.props.history.goBack()

    /* —————————————————————————————————————————————————— */

    didRegisterPuckMovement = false
    componentDidUpdate = (_prevProps, { doShowPuck: prevDoShowPuck }) => {
        if (
            this.didRegisterPuckMovement === false &&
            this.state.doShowPuck === true &&
            !isNaN(this.state.position.x) &&
            !isNaN(this.state.position.y)
        ) {
            // Assume that we're setting up for the first time.
            this.registerPuckMovement()
            this.didRegisterPuckMovement = true
        }
    }

    movementFunctions = {}
    MOVEMENT_INTERVAL_PX = 6
    ANIMATION_INTERVAL_MS = 16.666 // Roughly 60 FPS
    keydownHandlers = {
        // Up
        w: ({ x, y }) => ({ x, y: y - this.MOVEMENT_INTERVAL_PX }),
        // Left
        a: ({ x, y }) => ({ x: x - this.MOVEMENT_INTERVAL_PX, y }),
        // Down
        s: ({ x, y }) => ({ x, y: y + this.MOVEMENT_INTERVAL_PX }),
        // Right
        d: ({ x, y }) => ({ x: x + this.MOVEMENT_INTERVAL_PX, y }),
    }

    // activeAnimations = {}

    isCursorDragged = false

    // /**
    //  * The position of the puck's "focus."
    //  *
    //  * (FOR NOW, DO NOT USE.)
    //  * @type {Euclidean.Position}
    //  */
    focusDirectionDegrees = 0

    /**
     * Updates peer gains based on current puck positions and the current focus direction.
     * @returns {Promise<void>} When setState is complete.
     */
    updatePeerGains = async () => {
        // Get peers, and prepare to update peers.
        const peerIds = Object.keys(this.state.peers)
        const newPeersState = {
            ...this.state.peers,
        }
        // Calculate each peer-connected user's new gain.
        for (const peerId of peerIds) {
            let peerGain
            try {
                peerGain = this.calculatePeerGain(peerId)
            } catch (err) {
                console.error(err)
                continue
            }
            newPeersState[peerId].volumePercent = Number((peerGain * 100).toFixed(1))
        }
        // Apply the new peer gain.
        await new Promise((resolve) => this.setState({ peers: newPeersState }, resolve))
        this.applyPeerGainFromState()
    }

    registerPuckMovement = () => {
        console.log(`Register puck movement`)
        const roomId = this.props.match.params.room

        ////////////////////////////////////////
        // Audio focus direction pointer code, temporarily handled here

        // let focusPosition = { x: 0, y: 0 }

        /**
         * @param {Euclidean.Position} focusPosition
         * @returns {void}
         */
        const updateFocusDirection = (focusPosition) => {
            // Get angle.
            const newFocusDirectionDegrees = Number(
                Euclidean.calcAngle(this.state.position, focusPosition).toFixed(1),
            )

            if (this.focusDirectionDegrees !== newFocusDirectionDegrees) {
                // 1. Save new angle.
                this.focusDirectionDegrees = newFocusDirectionDegrees
                // 2. Rotate pointer to angle.
                const pointerEl = document.getElementById('myPointer')
                if (pointerEl !== null) {
                    console.log(`Rotating pointer to ${this.focusDirectionDegrees} deg`)
                    pointerEl.style.transform = 'rotate(' + this.focusDirectionDegrees + 'deg)'
                } else {
                    console.log(`pointerEl is null!`)
                }
            }
        }

        // /**
        //  * Updates peer gains based on current puck positions and the current focus position.
        //  * @returns {Promise<void>} When setState is complete.
        //  */
        // const updatePeerGains = async () => {
        //     // const myPuckEl = document.getElementById('myPuck')

        //     const peerIds = Object.keys(this.state.peers)
        //     const newPeersState = {
        //         ...this.state.peers,
        //     }
        //     // Calculate each peer-connected user's new gain.
        //     for (const peerId of peerIds) {
        //         let peerGain
        //         try {
        //             peerGain = this.calculatePeerGain(peerId, focusPosition)
        //         } catch (err) {
        //             console.error(err)
        //             continue
        //         }
        //         newPeersState[peerId].volumePercent = Number((peerGain * 100).toFixed(1))
        //     }

        //     await new Promise((resolve) => this.setState({ peers: newPeersState }, resolve))
        //     this.applyPeerGainFromState()
        // }

        window.addEventListener('mousedown', () => {
            this.isCursorDragged = true
        })
        window.addEventListener('mouseup', () => {
            this.isCursorDragged = false
        })

        window.addEventListener('mousemove', (e) => {
            if (this.isCursorDragged === true && this.isRefocusEnabled) {
                // Update focus position.
                const focusPosition = { x: e.clientX, y: e.clientY }
                // Update focus direction and pointer.
                updateFocusDirection(focusPosition)
                // Update each peer-connected user's gain.
                this.updatePeerGains()
            }
        })

        //////////////////////////////////////

        window.addEventListener('keydown', ({ key }) => {
            if (this.isMovementEnabled) {
                const animation = this.keydownHandlers[key]

                const keepGoing = async () => {
                    assert.notStrictEqual(
                        this.socket,
                        undefined,
                        `Can't animate with undefined socket`,
                    )
                    // Animate the state according to keydown handlers.
                    const newPosition = animation(this.state.position)

                    // Set the position, then update gains for peer-connected users.
                    const setPositionPromise = new Promise((resolve) =>
                        this.setState({ position: newPosition }, resolve),
                    )
                    setPositionPromise.then(this.updatePeerGains)

                    // Tell peers our new position.
                    this.socket.emit('set-public-params', roomId, this.state.peerId, {
                        x: newPosition.x,
                        y: newPosition.y,
                    })
                }

                if (typeof animation === 'function' && !this.movementFunctions[key]) {
                    this.movementFunctions[key] = setInterval(keepGoing, this.ANIMATION_INTERVAL_MS)
                }
            }
        })

        window.addEventListener('keyup', ({ key }) => {
            this.movementFunctions[key] = clearInterval(this.movementFunctions[key])
        })
    }

    /* —————————————————————————————————————————————————— */

    componentWillUnmount() {
        console.log(`============= UNMOUNTING =============`)
        // Kill socket and peer.
        if (this.socket !== null) {
            this.socket.disconnect()
        }
        if (this.peer !== null) {
            this.peer.destroy()
        }
        // Stop every local track.
        for (const track of Object.values(this.myTracks)) {
            if (track !== null) {
                try {
                    track.stop()
                } catch (_e) {
                    // Do nothing.
                }
            }
        }
        // Stop every received stream track.
        for (const { stream } of Object.values(this.state.peers)) {
            if (Util.exists(stream)) {
                try {
                    stream.getTracks().forEach((track) => track.stop())
                } catch (_e) {
                    // Do nothing.
                }
            }
        }
    }

    /* —————————————————————————————————————————————————— */

    /** @returns {[string, PeerData, PeerDetails][]} */
    getFullPeerDetails = () => {
        const peerDataEntries = Object.entries(this.peersData)
        const fullPeerDetails = []
        // console.log(`getFullPeerDetails`, peerDataEntries)

        for (const peerDataEntry of peerDataEntries) {
            const [peerId] = peerDataEntry
            // If we've gathered UI state on this peer, prepare to render it.
            if (this.state.peers[peerId] !== undefined) {
                peerDataEntry.push(this.state.peers[peerId])
                fullPeerDetails.push(peerDataEntry)
            }
        }
        return fullPeerDetails
    }

    /* —————————————————————————————————————————————————— */

    render() {
        const roomId = this.props.match.params.room
        // Show loading screen if loading.
        if (this.state.didJoinRoom === false) {
            return (
                <LoadingScreen>
                    <h2>Brewing mocha in room {roomId}...</h2>
                </LoadingScreen>
            )
        }
        //console.log('rendering!')

        // Determine whether to display own stream.
        const doShowPuck = this.state.doShowPuck
        const myPuckWrapperStyle =
            !isNaN(this.state.position.x) && !isNaN(this.state.position.y)
                ? {
                      position: 'absolute',
                      top: this.state.position.y - this.puckSize / 2,
                      left: this.state.position.x - this.puckSize / 2,
                      maxWidth: this.puckSize,
                  }
                : ''

        // Determine whether to display action bar.
        // For the time being, we disable it when the socket isn't accessible so that
        // users can't trigger effects that would rely on socket transmissions.
        const doShowActionBar = doShowPuck && this.state.peerId !== undefined

        // Determine what peer streams to show.
        const streamablePeers = this.getFullPeerDetails().filter(
            ([_peerId, _peerData, peerDetails]) =>
                peerDetails.stream !== undefined && peerDetails.disconnected !== true,
        )

        // Get style for puck of other user in call.
        const getPuckWrapperStyle = (peerId) => {
            if (
                typeof this.state.peers[peerId] === 'object' &&
                !isNaN(this.state.peers[peerId].x) &&
                !isNaN(this.state.peers[peerId].y)
            ) {
                return {
                    position: 'absolute',
                    top: this.state.peers[peerId].y - this.puckSize / 2,
                    left: this.state.peers[peerId].x - this.puckSize / 2,
                    maxWidth: this.puckSize,
                }
            } else {
                return {}
            }
        }

        return (
            <>
                {/* <div id="pingArea">
                    Ping:{' '}
                    <div className="_ping">
                        {this.state.latencyMs ? `${this.state.latencyMs}ms` : ''}
                    </div>
                </div> */}
                <Helmet>
                    <title>Room {roomId} | Concord</title>
                </Helmet>

                <Modal
                    open={window.location.pathname === `/${roomId}/settings`}
                    onClose={this.handleCloseSettingsModal}
                >
                    <h1>Nickname</h1>

                    <form onSubmit={this.handleSetNickname} className="_nicknameInputSet">
                        <div>
                            <label htmlFor="setNicknameField">What should we call you?</label>
                        </div>
                        <div
                            className={`bigInputPane ${
                                this.state.nicknameFieldInput !== '' ? 'filled' : ''
                            }`}
                        >
                            <input
                                type="text"
                                id="setNicknameField"
                                className="nicknameField"
                                placeholder="Your nickname"
                                value={this.state.nicknameFieldInput}
                                onInput={(e) =>
                                    this.setState({ nicknameFieldInput: e.currentTarget.value })
                                }
                            ></input>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button
                                type="submit"
                                className={`secondaryButton ${
                                    this.state.nicknameFieldInput.length !== 0 ? '' : 'disabled'
                                }`}
                            >
                                👋 That's me!
                            </button>
                        </div>
                    </form>
                </Modal>

                <div id="roomContent">
                    {/* Topbar. */}
                    <div id="topBar">
                        <h2>☕ {roomId}</h2>
                        <div id="actionBarInfo">
                            <TooltipMajor
                                title={
                                    <>
                                        <p>Room ID: {roomId}</p>
                                        {this.state.peerId !== null && (
                                            <p>My ID: {this.state.peerId}</p>
                                        )}

                                        {this.state.latencyMs && (
                                            <p>Latency: {this.state.latencyMs}ms</p>
                                        )}
                                    </>
                                }
                            >
                                <InfoIcon fontSize="inherit" />
                            </TooltipMajor>

                            <TooltipBootstrap title={'Copy invite link'}>
                                <button
                                    id="copyRoomLinkButton"
                                    className="tertiaryButton iconButton"
                                    onClick={this.handleClickCopyLink}
                                >
                                    <LinkIcon fontSize="inherit" />
                                </button>
                            </TooltipBootstrap>
                        </div>
                        <div className="_barRight">
                            {/* <Tooltip title="Share Documents" placement="top"> */}
                            <div className="numGuests">
                                {streamablePeers.length + 1}{' '}
                                {streamablePeers.length > 0 ? 'guests' : 'guest'}
                            </div>
                            {/* <Tooltip title="Share Documents" placement="top">
                                <div id="documentShareButton">
                                    <button
                                        className="tertiaryButton iconButton"
                                        onClick={this.handleSelfToggleMute}
                                    >
                                        <DocumentShareIcon fontSize="inherit" />
                                    </button>
                                </div>
                            </Tooltip>
                            <Tooltip title="Whiteboard" placement="top">
                                <div id="WhiteboardButton">
                                    <button
                                        className="tertiaryButton iconButton"
                                        onClick={this.handleSelfToggleMute}
                                    >
                                        <WhiteboardIcon fontSize="inherit" />
                                    </button>
                                </div>
                            </Tooltip>
                            <Tooltip title="Share Screen" placement="top">
                                <div id="ShareScreenButton">
                                    <button
                                        className="tertiaryButton iconButton"
                                        onClick={this.handleSelfToggleMute}
                                    >
                                        <ShareScreenIcon fontSize="inherit" />
                                    </button>
                                </div>
                            </Tooltip>
                            */}
                        </div>
                    </div>
                    {/* <h2>Room</h2>
                    <p>Room ID: {this.props.match.params.room}</p>
                    {this.state.peerId !== null && <p>My ID: {this.state.peerId}</p>} */}

                    {/* <div id="videoGrid">
                    {/* Our video. Mute our video for ourselves. */}
                    {doShowPuck && (
                        <div className="puckWrapper" id="myPuckWrapper" style={myPuckWrapperStyle}>
                            <div
                                className="puck"
                                id="myPuck"
                                style={{ width: this.puckSize, height: this.puckSize }}
                            >
                                <video
                                    ref={this.myVideoRef}
                                    onLoadedMetadata={this.handleVideoLoadedMetadata}
                                />
                                <div
                                    className="pointer"
                                    id="myPointer"
                                    style={{ transformOrigin: this.pointerTransformOrigin }}
                                />
                            </div>
                            {this.state.isMuted ? (
                                <div className="muteIndicator">
                                    <MicOffIcon fontSize="inherit" />
                                </div>
                            ) : (
                                <div></div>
                            )}{' '}
                            <ul className="_puckContext">
                                {/* {this.state.isNoCam && <li className="_noCam">No cam</li>} */}
                            </ul>
                        </div>
                    )}
                    {/* Others' videos. */}
                    {streamablePeers.map(([peerId, peerData, peerDetails]) => (
                        <div
                            className="puckWrapper"
                            key={peerId}
                            style={getPuckWrapperStyle(peerId)}
                        >
                            <div
                                className="puck"
                                style={{ width: this.puckSize, height: this.puckSize }}
                            >
                                <video
                                    ref={peerData.videoRef}
                                    onLoadedMetadata={this.handleVideoLoadedMetadata}
                                />
                            </div>
                            {peerDetails.muted ? (
                                <div className="muteIndicator">
                                    <MicOffIcon fontSize="inherit" />
                                </div>
                            ) : (
                                <div></div>
                            )}{' '}
                            <ul className="_puckContext">
                                {/* {peerDetails.noCam === true && <li className="_noCam">No cam</li>} */}
                                {this.state.dispVolumePercent === true &&
                                    this.state.deafen === false && (
                                        <li className="_noCam"> {peerDetails.volumePercent} %</li>
                                    )}
                                {this.state.deafen === true &&
                                    this.state.dispVolumePercent === true && (
                                        <li className="_noCam"> 0%</li>
                                    )}
                            </ul>
                        </div>
                    ))}
                    {/* </div> */}
                </div>
                {doShowActionBar && (
                    <div id="actionBar">
                        <div className="_userBar">
                            <Tooltip title={'Settings'}>
                                <div
                                    className="_userCard growSlight"
                                    onClick={this.handleOpenSettingsModal}
                                >
                                    <div className="_icon">
                                        <AccountCircleIcon fontSize="inherit" />
                                    </div>
                                    <div className="_nickname">{this.state.myNickname}</div>
                                </div>
                            </Tooltip>

                            <Tooltip title={this.state.isMuted ? 'Unmute' : 'Mute'} placement="top">
                                <div id="selfMuteButton">
                                    <button
                                        className="tertiaryButton iconButton"
                                        onClick={this.handleSelfToggleMute}
                                    >
                                        {this.state.isMuted ? (
                                            <MicOffIcon fontSize="inherit" />
                                        ) : (
                                            <MicIcon fontSize="inherit" />
                                        )}
                                    </button>
                                    {/* <div className="_label">{this.state.isMuted ? 'Unmute' : 'Mute'}</div> */}
                                </div>
                            </Tooltip>
                            <div className="_horizontalSpacing" />
                            <Tooltip
                                title={this.state.isNoCam ? 'Turn on camera' : 'Turn off camera'}
                                placement="top"
                            >
                                <div id="selfNoCamButton">
                                    <button
                                        className="tertiaryButton iconButton"
                                        onClick={this.handleSelfToggleCam}
                                    >
                                        {this.state.isNoCam ? (
                                            <VisibilityOffIcon fontSize="inherit" />
                                        ) : (
                                            <VisibilityIcon fontSize="inherit" />
                                        )}
                                    </button>
                                    {/* <div className="_label">{this.state.isNoCam ? 'Turn on cam' : 'Turn off cam'}</div> */}
                                </div>
                            </Tooltip>

                            <Tooltip
                                title={this.state.deafen ? 'Turn Deafen Off' : 'Deafen'}
                                placement="top"
                            >
                                <div id="deafenButton">
                                    <button
                                        className="tertiaryButton iconButton"
                                        onClick={this.handleDeafen}
                                    >
                                        {this.state.deafen ? (
                                            <DeafenOffIcon fontSize="inherit" />
                                        ) : (
                                            <DeafenIcon fontSize="inherit" />
                                        )}
                                    </button>
                                    {/* <div className="_label">{this.state.isNoCam ? 'Turn on cam' : 'Turn off cam'}</div> */}
                                </div>
                            </Tooltip>
                        </div>

                        <div className="_barRight">
                            <Tooltip
                                title={
                                    this.state.dispVolumePercent
                                        ? 'Hide Peer Volume'
                                        : 'Show Peer Volume'
                                }
                                placement="top"
                            >
                                <div id="selfVolButton">
                                    <button
                                        className="tertiaryButton iconButton"
                                        onClick={this.handleSelfToggleVol}
                                    >
                                        {this.state.dispVolumePercent ? (
                                            <VolumePercentIcon fontSize="inherit" />
                                        ) : (
                                            <VolumePercentOffIcon fontSize="inherit" />
                                        )}
                                    </button>
                                    {/* <div className="_label">{this.state.isNoCam ? 'Turn on cam' : 'Turn off cam'}</div> */}
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </>
        )
    }
}
