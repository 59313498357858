/**
 * NOTE: Though this is titled "EuclideanUtils", all calculations will be done with the assumption
 * that y is 0 at the top of the plane and increases as we descend.
 */

const RADIANS_TO_ANGLES = 180 / Math.PI

/**
 * Represents a 2D position.
 * @typedef {{ x: number, y: number }} Position
 */

/** An exception type for describing an invalid position. */
export class InvalidPositionError extends Error {
    constructor(message) {
        super(message)

        // Set the prototype explicitly (well-known `Error` gotcha).
        Object.setPrototypeOf(this, InvalidPositionError.prototype)
    }
}

/**
 * @returns {true}
 * @throws {InvalidPositionError} When a provided position is invalid.
 */
export function validatePosition(...positions) {
    for (const position of positions) {
        // console.log(position)
        // For now, assume each given position is a non-null object.
        if (isNaN(position.x)) {
            throw new InvalidPositionError(
                `Position ${JSON.stringify(position)} has NaN property x: '${position.x}'`,
            )
        }
        if (typeof position.x !== 'number') {
            throw new InvalidPositionError(
                `Position ${JSON.stringify(position)} has non-number property x: '${position.x}'`,
            )
        }
        if (isNaN(position.y)) {
            throw new InvalidPositionError(
                `Position ${JSON.stringify(position)} has NaN property y: '${position.y}'`,
            )
        }
        if (typeof position.y !== 'number') {
            throw new InvalidPositionError(
                `Position ${JSON.stringify(position)} has non-number property y: '${position.y}'`,
            )
        }
    }
    return true
}

/**
 * @param {Position} a
 * @param {Position} b
 * @return {number} Euclidean distance between a and b, squared.
 */
export const distSquared = (a, b) => {
    return Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2)
}

export {
    distSquared as distanceSquared,
    distSquared as calcDistSquared,
    distSquared as calcDistanceSquared,
}

/**
 * @param {Position} a
 * @param {Position} b
 * @return {number} Euclidean distance between a and b.
 */
export const dist = (a, b) => {
    return Math.sqrt(distSquared(a, b))
}

export { dist as distance, dist as calcDist, dist as calcDistance }

/**
 * @param {Position} a
 * @param {Position} b
 * @return {number} The angle of the ray drawn from a to b, in radians in the range [-π, π),
 * from the positive-x axis clockwise.
 */
export const calcAngleRadians = (a, b) => {
    const rayX = b.x - a.x
    const rayY = b.y - a.y
    const angleRadians = Math.atan2(rayY, rayX)
    return angleRadians
}

/**
 * @param {Position} a
 * @param {Position} b
 * @return {number} The angle of the ray drawn from a to b, in degrees in the range [0, 360),
 * from the positive-x axis clockwise.
 */
export const calcAngleDegrees = (a, b) => {
    const angleRadians = calcAngleRadians(a, b)
    let angleDegrees = angleRadians * RADIANS_TO_ANGLES
    // Re-represent angles in the range [-180, 0) as positive angles.
    if (angleDegrees < 0) {
        angleDegrees += 360
    }
    return angleDegrees
}

export { calcAngleDegrees as calcAngle }

/**
 * @param {number} angle Angle represented in any numeric range.
 * @returns Angle in the range [0, 360).
 */
export const normalizeAngleDegrees = (angle) => {
    while (angle >= 360) {
        angle -= 360
    }
    while (angle < 0) {
        angle += 360
    }
    return angle
}

export { normalizeAngleDegrees as normalizeAngle }
