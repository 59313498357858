import io, { Socket } from 'socket.io-client'

/** @returns {Socket} */
export const instantiateIo = () => {
    const host =
        typeof process.env.REACT_APP_WS_HOST === 'string' && process.env.REACT_APP_WS_HOST !== 'default'
            ? process.env.REACT_APP_WS_HOST
            : '/'
    return io(host)
}
