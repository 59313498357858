/**
 * @param {number} milliseconds
 * @returns {Promise}
 */
export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

/**
 * @param {T} val
 * @returns {val is NonNullable<T>}
 */
export function exists(val) {
  return val !== undefined && val !== null
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * (If you set 'immediate' to true, the very last event may not fire.)
 * @param {() => void} func
 * @param {number} waitMs
 * @param {boolean} immediate
 * @returns
 */
export function debounce(func, waitMs, immediate) {
  let timeout
  return function () {
    let context = this,
      args = arguments
    let later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, waitMs)
    if (callNow) func.apply(context, args)
  }
}
