import React from 'react'

export class ErrorBoundary extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props)

        this.state = { hasError: false, error: null }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error }
    }

    componentDidCatch(err, info) {
        console.log('CAUGHT ERROR')
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children
        } else {
            return (
                <div>
                    <p>We're sorry - something's gone wrong.</p>
                    <p>{JSON.stringify(this.state)}</p>
                </div>
            )
        }
    }
}
