/**
 * Wraps the Local Storage API (localStorage.getItem and localStorage.setItem).
 */

/**
 * @param {'myNickname'} itemName
 * @returns {string | null} Either the item, or null.
 */
export const get = (itemName) => {
    const result = localStorage.getItem(itemName)
    if (result === 'null' || result === 'undefined' || result === undefined) {
        return null
    } else {
        return result
    }
}

/** @returns {void} */
export const set = (itemName, value) => {
    localStorage.setItem(itemName, value)
}
