import React from 'react'
import ReactDOM from 'react-dom'

import { ErrorBoundary } from './components/ErrorBoundary'

import './styles/lib/bootstrap-grid.min.css'
import './styles/lib/normalize.css'
import './styles/index.scss'
import './styles/global.scss'

import App from './App'

ReactDOM.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
    document.getElementById('root'),
)
