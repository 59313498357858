import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import './LoadingScreen.scss'

function LoadingScreen({ children }) {
    return (
        <div id="loadingRoom">
            <div className="_centered">
                <CircularProgress color="inherit" />
                {children}
            </div>
        </div>
    )
}

export { LoadingScreen }
