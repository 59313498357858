/**
 * Component for Bootstrap-styled tooltips.
 *
 * See https://material-ui.com/components/tooltips/#customized-tooltips for detail.
 */

 import React from 'react'
 import { makeStyles } from '@material-ui/core/styles'
 import Tooltip from '@material-ui/core/Tooltip'
 
 const useStylesBootstrap = makeStyles((theme) => {
     // const TOOLTIP_COLOR = theme.palette.common.black
     const TOOLTIP_COLOR = 'rgba(45, 42, 42, 0.75)'
     return {
         arrow: {
             color: TOOLTIP_COLOR,
         },
         tooltip: {
             // backgroundColor: theme.palette.common.black,
             backgroundColor: TOOLTIP_COLOR,
             fontSize: 12,
         },
     }
 })
 
 export function TooltipBootstrap(props) {
     const classes = useStylesBootstrap()
 
     return <Tooltip arrow classes={classes} {...props} leaveDelay={150} interactive />
 }
 