import animals from 'animals'

import sum from 'lodash/sum'

const PREFIXES_WITH_WEIGHTS = {
    // Meaning 'anonymous'
    Anonymous: 100,
    Secretive: 10,
    Elusive: 5,
    Discreet: 5,
    // Meaning 'willing to talk'
    Chatty: 20,
    Enthusiastic: 20,
    Eloquent: 20,
    'Silver-Tongued': 20,
    Jabbering: 20,
    // Fun modifiers
    'Ultra Rare': 1,
    Golden: 1,
    Shiny: 0.5,
}
const PREFIX_ENTRIES = Object.entries(PREFIXES_WITH_WEIGHTS)
const PREFIX_WEIGHT_TOTAL = sum(Object.values(PREFIXES_WITH_WEIGHTS))

export function generateNickname() {
    let myPrefix
    {
        // Pick prefix form weighted entries.
        let prefixRoll = Math.random() * PREFIX_WEIGHT_TOTAL
        let prefixesIdx = 0
        while (true) {
            const [prefix, weight] = PREFIX_ENTRIES[prefixesIdx]
            prefixRoll -= weight
            if (prefixRoll < 0) {
                myPrefix = prefix
                break
            }
            if (prefixesIdx === PREFIX_ENTRIES.length - 1) {
                myPrefix = prefix
                break
            }
        }
    }

    // Pick suffix; capitalize first letter.
    let mySuffix = animals()
    mySuffix = mySuffix.slice(0, 1).toLocaleUpperCase() + mySuffix.slice(1)

    return `${myPrefix} ${mySuffix}`
}
