import * as Euclidean from './euclideanUtils'

// ————————————————————————————————————

/** Maximum distance for which each person can hear (in units of most ease). */
const EAR_RANGE = 700 //
/** Setting distance to impact 60% of audio. */
const DISTANCE_WEIGHT = 0.6
/** Setting rotation to impact 40% of audio. */
const ROTATIONAL_WEIGHT = 0.4

// ————————————————————————————————————

//myPuck, otherPuck - a "puck" object containing references to a puck's central x,y position and MediaStream
//cursorLoc - contains the x,y coordinates of the user's cursor
export const audioCalc = (myPuck, otherPuck, cursorLocX, cursorLocY) => {
    let audioStream = otherPuck.stream.getAudioTracks() //queues the audio stream of another puck for editing

    //calculate the effects of distance on audio in a linear falloff
    let distBetween = distanceCalc(myPuck.x, myPuck.y, otherPuck.x, otherPuck.y) //gets the distance between 2 pucks
    let percentAudibleDist
    if (distBetween <= EAR_RANGE) {
        percentAudibleDist = Math.pow((EAR_RANGE - distBetween) / EAR_RANGE, 2)
    }
    //console.log(percentAudibleDist)
    //calculate the effects of rotation on audio with a linear falloff
    let focusAngle = angleCalc(myPuck.x, myPuck.y, cursorLocX, cursorLocY) //angle between cursor and x axis in radians
    let otherPuckAngle = angleCalc(myPuck.x, myPuck.y, otherPuck.x, otherPuck.y) //angle between otherPuck and the
    let rotationAngle = otherPuckAngle - focusAngle
    let percentAudibleRot = (focusAngle + Math.PI - rotationAngle) / (focusAngle + Math.PI) //fraction of the audio you can hear based on Rotation

    console.log(focusAngle)
    //normalize the cumulative effects of distance and rotation on volume
    audioStream.gain = percentAudibleDist * DISTANCE_WEIGHT + percentAudibleRot * ROTATIONAL_WEIGHT //set the audio gain (volume). maybe is different function?

    return audioStream.gain.toFixed(10) * 100
}

/**
 * Represents a 2D position.
 * @typedef {{ x: number, y: number }} Position
 */

/**
 * @param {Position} puck Location of a peer-connected user's puck.
 * @returns {number} A number between 0 and 1 representing the percentage at which the puck's audio
 * should be heard.
 */
export const calcPuckGain = ([myPuck, myFocusDirection], puck) => {
    // 1. Determine impact of distance.
    const puckDistance = Euclidean.dist(myPuck, puck)
    // In range [0, 1].
    let distanceBasedGain = 0
    if (puckDistance < EAR_RANGE) {
        // Falloff is linear WRT distance.
        distanceBasedGain = (EAR_RANGE - puckDistance) / EAR_RANGE
    }

    // 2. Determine impact of rotation.
    // Angle of "focus", aka ray from puck to mouse.
    const castRayAngleDegrees = Euclidean.normalizeAngle(myFocusDirection)
    // Angle of ray to other puck.
    const angleBetweenPucksDegrees = Euclidean.calcAngle(myPuck, puck)
    // Angle to swivel from focus angle to ray-between-pucks angle.
    const rotationAngleDegrees = Euclidean.normalizeAngle(
        castRayAngleDegrees - angleBetweenPucksDegrees,
    )
    // In range [0, 1].
    // Falloff is linear WRT angle.
    let rotationBasedGain = Math.abs(180 - rotationAngleDegrees) / 180

    // Final equation: normalize the cumulative effects of distance and rotation on volume.
    const gain = distanceBasedGain * DISTANCE_WEIGHT + rotationBasedGain * ROTATIONAL_WEIGHT
    console.log({
        myPuck: JSON.stringify(myPuck),
        puck: JSON.stringify(puck),
        puckDistance,
        castRayAngleDegrees,
        angleBetweenPucksDegrees,
        rotationAngleDegrees,
        distanceBasedGain,
        rotationBasedGain,
        gain,
    })
    return Number(gain.toFixed(3)) // Round to nearest 1/1000th.
}

//calculates the angle of "two" with respect to "one"
export const angleCalc = (oneX, oneY, twoX, twoY) => {
    let width = twoX - oneX
    let height = twoY - oneY
    let angle = Math.atan2(height, width)

    return angle
}

function distanceCalc(oneX, oneY, twoX, twoY) {
    let width = twoX - oneX
    let height = twoY - oneY
    let distance = Math.sqrt(Math.pow(height, 2) + Math.pow(width, 2))

    return distance
}
