/**
 * A custom modal, for ease-of-use.
 *
 * Please see the propTypes for usage instructions.
 *
 * Intended as a replacement for the barebones @material-ui Modal.
 */

import React from 'react'
import PropTypes from 'prop-types'

import Modal from '@material-ui/core/Modal'

import './Modal.scss'

class MyModal extends React.Component {
    render() {
        return (
            <Modal open={this.props.open} onClose={this.props.onClose}>
                <div className="modalWrapper">
                    <div className="myModal">
                        {this.props.children}

                        {this.props.showCloseButton && (
                            <button
                                onClick={
                                    this.props.onClickClose !== undefined
                                        ? this.props.onClickClose
                                        : this.props.onClose
                                }
                                className="_close"
                            />
                        )}
                    </div>
                </div>
            </Modal>
        )
    }
}

MyModal.defaultProps = {
    showCloseButton: true,
}

MyModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,

    /** Defaults to true, but can be specified otherwise. */
    showCloseButton: PropTypes.bool,
    /**
     * Only fill this out if you want super-specific custom behavior when
     * the X button is explicitly clicked, as opposed to `onClose` (regular closing.)
     */
    onClickClose: PropTypes.func,
}

export { MyModal as Modal }
