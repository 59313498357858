/**
 * Component for large tooltips.
 */

 import React from 'react'
 import { withStyles } from '@material-ui/core/styles'
 import Tooltip from '@material-ui/core/Tooltip'
 
 const HtmlTooltip = withStyles((theme) => ({
     tooltip: {
         backgroundColor: '#f5f5f9',
         color: 'rgba(0, 0, 0, 0.87)',
         maxWidth: 220,
         fontSize: theme.typography.pxToRem(12),
         border: '1px solid #dadde9',
     },
 }))(Tooltip)
 
 function TooltipMajor({ title, children }) {
     return <HtmlTooltip title={title !== undefined ? title : 'Default'}>{children}</HtmlTooltip>
 }
 
 TooltipMajor.propTypes = {
     // TODO: Fill this out
 }
 
 export { TooltipMajor }
 