import assert from 'assert'
import Peer from 'peerjs'

import * as Util from './util'

/**
 * @param {string | undefined} id If left undefined, oen will be generated.
 * @param {import("peerjs").PeerConnectOption | undefined} customPeerOptions
 * @returns {Peer}
 */
export const instantiatePeer = (id = undefined, customPeerOptions = {}) => {
    // Docs: https://peerjs.com/docs.html#start
    const host =
        process.env.REACT_APP_PEER_HOST !== undefined && process.env.REACT_APP_PEER_HOST !== ''
            ? process.env.REACT_APP_PEER_HOST
            : '/'
    const path =
        process.env.REACT_APP_PEER_PATH !== undefined && process.env.REACT_APP_PEER_PATH !== ''
            ? process.env.REACT_APP_PEER_PATH
            : '/'
    const shouldUseCustomPort =
        process.env.REACT_APP_PEER_PORT !== undefined &&
        process.env.REACT_APP_PEER_PORT !== 'default' &&
        !isNaN(process.env.REACT_APP_PEER_PORT)
    const port = shouldUseCustomPort ? parseInt(process.env.REACT_APP_PEER_PORT) : 443
    let peerOptions = { host, path, port }

    peerOptions = Object.assign({}, peerOptions, customPeerOptions)
    return new Peer(undefined, peerOptions)
}

/**
 * @returns {MediaStreamTrack} Audio MediaStreamTrack. Silent. Ready to be sent over the wire.
 */
export const createDummyAudioTrack = () => {
    let ctx = new AudioContext(),
        oscillator = ctx.createOscillator()
    let dst = oscillator.connect(ctx.createMediaStreamDestination())
    oscillator.start()
    return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false })
}

/**
 * @param {'camera' | 'screen'} videoType
 * @returns {MediaStreamTrack} Video MediaStreamTrack. Just a blank rectangle. Ready to be sent over the wire.
 */
export const createDummyVideoTrack = (videoType = 'camera') => {
    const WIDTH = 1280
    const HEIGHT = 960

    let canvas = Object.assign(document.createElement('canvas'), { width: WIDTH, height: HEIGHT })
    const ctx = canvas.getContext('2d')
    if (videoType === 'camera') {
        ctx.fillStyle = '#550000'
    } else if (videoType === 'screen') {
        ctx.fillStyle = '#000055'
    }
    ctx.fillRect(0, 0, WIDTH, HEIGHT)

    let stream = canvas.captureStream()
    return Object.assign(stream.getVideoTracks()[0], { enabled: false })
}

// /**
//  * @returns {MediaStream} Stream containing only dummy video and audio tracks.
//  */
// export const instantiateLocalStream = () => {
//     return new MediaStream([createDummyAudioTrack(), createDummyVideoTrack('camera')])
// }

/**
 * Swap in either a new video MediaStreamTrack or a new audio MediaStreamTrack, both locally and
 * remotely. Then, return the swapped-out video or audio MediaStreamTrack.
 *
 * @param {MediaStream} localStream
 * @param {RTCPeerConnection[]} peerConnections
 * @param {"audio" | "video"} trackType
 * @param {MediaStreamTrack} newMediaTrack
 *
 * @return {MediaStreamTrack} The old media track.
 */
const swapMediaTrack = (localStream, peerConnections, trackType, newMediaTrack) => {
    assert(Util.exists(localStream, peerConnections, trackType, newMediaTrack))
    assert(['audio', 'video'].includes(trackType))
    assert(newMediaTrack.kind === trackType)

    // 1. Get old media track.
    let oldMediaTrack
    if (trackType === 'audio') {
        localStream.getAudioTracks().forEach((track) => (oldMediaTrack = track))
    } else if (trackType === 'video') {
        localStream.getVideoTracks().forEach((track) => (oldMediaTrack = track))
    }

    // 2. Replace track on our local stream.
    localStream.removeTrack(oldMediaTrack)
    localStream.addTrack(newMediaTrack)

    // 3. For each of our peerConnections, replace the outgoing track.
    for (const pc of peerConnections) {
        const senders = pc.getSenders()
        const relevantSenderType = senders.find((s) => s.track.kind === newMediaTrack.kind)
        relevantSenderType.replaceTrack(newMediaTrack)
    }

    return oldMediaTrack
}

/**
 * Swap in either a video MediaStreamTrack, both locally and remotely. Then, return the swapped-out
 * video MediaStreamTrack.
 *
 * @param {MediaStream} localStream
 * @param {RTCPeerConnection[]} peerConnections
 * @param {MediaStreamTrack} newVideoTrack
 *
 * @return {MediaStreamTrack} The swapped-out video track.
 */
export const swapVideoTrack = (localStream, peerConnections, newVideoTrack) =>
    swapMediaTrack(localStream, peerConnections, 'video', newVideoTrack)

/**
 * Swap in either a audio MediaStreamTrack, both locally and remotely. Then, return the swapped-out
 * audio MediaStreamTrack.
 *
 * @param {MediaStream} localStream
 * @param {RTCPeerConnection[]} peerConnections
 * @param {MediaStreamTrack} newAudioTrack
 *
 * @return {MediaStreamTrack} The old audio track.
 */
export const swapAudioTrack = (localStream, peerConnections, newAudioTrack) =>
    swapMediaTrack(localStream, peerConnections, 'audio', newAudioTrack)
